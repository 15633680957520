import { useState } from "react"
import { useLogin } from "../hooks/useLogin"

import loading from "../assets/loading.svg"

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { login, isPending, error } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <form
      className="mt-24 flex flex-col justify-center items-center"
      onSubmit={handleSubmit}
    >
      <h2 className="text-4xl font-bold text-center mb-3">Login</h2>
      <input
        className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2"
        type="email"
        placeholder="Email"
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <input
        className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2"
        type="password"
        placeholder="Password"
        onChange={e => setPassword(e.target.value)}
        value={password}
      />
      <button
        className="bg-green-600 text-white px-5 py-2 rounded-md shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
        type="submit"
        disabled={isPending}
      >Login
        {isPending && <img className="inline-block w-5 ml-2 animate-spin" src={loading} alt="loading..." />}
      </button>

      {error && <p className="text-red-500 text-center">{error}</p>}
    </form>
  )
}
