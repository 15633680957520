import trash from '../assets/trash.svg';
import next from '../assets/arrow-right-circle.svg';
import prev from '../assets/arrow-left-circle.svg';

import { useFirestore } from '../hooks/useFirestore';

export default function Cards({ data: datas, list }) {
  const { deleteDocument, updateDocument } = useFirestore('todos');

  datas.sort((a, b) => {
    return a.Time.seconds - b.Time.seconds;
  })

  return (
    datas
      .filter(data => data.state === list)
      .map(data => {
        return (
          <div key={data.id} className="bg-white rounded-3xl mb-3">

            <div className='flex justify-between items-center'

            >
              <div className="flex flex-col px-3 py-2">
                <span className=''>{data.title}</span>
                <span className="text-[.7rem] text-gray-600">{data.priority}</span>
              </div>
              {list !== 'progress' && (
                <div
                  onClick={() => deleteDocument(data.id)}
                  className="m-3 text-red-600 cursor-pointer">
                  <img className='w-6' src={trash} alt='trash' />
                </div>
              )}
            </div>
            {data.priority === 'low' && <div className='border-b-2 border-green-500'></div>}
            {data.priority === 'medium' && <div className='border-b-2 border-yellow-500'></div>}
            {data.priority === 'high' && <div className='border-b-2 border-red-500'></div>}
            
            <div className='px-3 pt-2'>
              {data.description}
            </div>

            <div className='flex justify-between items-center'>
              {list !== 'todo' && (
                <span><img
                  className='w-6 mx-6 my-2 cursor-pointer'
                  src={prev}
                  alt="prev"
                  onClick={() => {
                    switch (list) {
                      case 'progress':
                        updateDocument(data.id, { state: 'todo' })
                        break;
                      case 'completed':
                        updateDocument(data.id, { state: 'progress' })
                        break;
                      default:
                        break;
                    }
                  }}
                />
                </span>
              )}
              {list === 'todo' && (
                <span></span>
              )}
              {list !== 'completed' && (
                <span><img
                  className='w-6 mx-6 my-2 cursor-pointer'
                  src={next}
                  alt="next"
                  onClick={() => {
                    switch (list) {
                      case 'todo':
                        updateDocument(data.id, { state: 'progress' })
                        break;
                      case 'progress':
                        updateDocument(data.id, { state: 'completed' })
                        break;
                      default:
                        break;
                    }
                  }}
                />
                </span>
              )}
            </div>
          </div>
        )
      })
  )
}
