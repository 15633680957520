import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { auth } from "../firebase/config";

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, name) => {
    setError(null);
    setIsPending(true);
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password);
      
      if (!res) {
        throw new Error("Error");
      }

      await res.user.updateProfile({
        displayName: name
      });

      dispatch({
        type: "LOGIN",
        payload: res.user
      });
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }

  }

  useEffect(()=> () => setIsCancelled(true), []);

  return { error, isPending, signup };
};