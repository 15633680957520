import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";

import loading from "../assets/loading.svg";
import { useHistory } from "react-router-dom";

export default function Create() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('low');
  const [titleClass, setTitleClass]= useState('mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2 w-[268px]')

  const { user } = useAuthContext();

  const { addDocument, response } = useFirestore('todos');

  const handleSubmit = (e) => {
    e.preventDefault();
    setTitleClass('mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2 w-[268px]')
    if (title.trim() === '') {
      setTitleClass('mb-3 border-2 rounded-lg border-red-400 focus:outline-none p-2 w-[268px]')
    } else {
    addDocument({
      uid: user.uid,
      title,
      description,
      priority,
      state: 'todo'
    });
  }
  };
  const history = useHistory();
  useEffect(() => {
    if (response.success) {
      //redirect to home
      history.push('/');
    }
    // eslint-disable-next-line
  }, [response.success]);


  return (
    <>
      <form
        className="mt-24 flex flex-col justify-center items-center"
        onSubmit={handleSubmit}
      >
        <h2 className="text-4xl font-bold text-center mb-3">Add Todo</h2>
        <input
          className={titleClass}
          type="text"
          placeholder="Title"
          onChange={e => setTitle(e.target.value)}
          value={title}
        />
        <select
          className="mb-3 border-2 rounded-lg bg-white border-green-500 focus:outline-none p-2 w-[268px]"
          onChange={e => {

            setPriority(e.target.value)

            if (e.target.value === 'low') {
              e.target.className = 'mb-3 border-2 rounded-lg bg-white border-green-500 focus:outline-none p-2 w-[268px]'
            } else if (e.target.value === 'medium') {
              e.target.className = 'mb-3 border-2 rounded-lg bg-white border-[#f59e0b] focus:outline-none p-2 w-[268px]'
            } else if (e.target.value === 'high') {
              e.target.className = 'mb-3 border-2 rounded-lg bg-white border-red-500 focus:outline-none p-2 w-[268px]'
            }

          }}>
          <option selected value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
        <textarea
          className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2 resize-none w-[268px]"
          placeholder="Description"
          onChange={e => setDescription(e.target.value)}
          value={description}
        />
        <button
          className="bg-green-600 text-white px-5 border py-2 rounded-md shadow-sm hover:shadow-lg disabled:opacity-70"
          type="submit"
          disabled={response.isPending}
        >Add
          {response.isPending && <img className="inline-block w-5 ml-2 animate-spin" src={loading} alt="..." />}
        </button>

        {response.error && <p className="text-red-500 text-center">{response.error}</p>}

      </form>
      <div className="text-center mt-4">
        <Link
          className="bg-red-600 text-white px-5 border py-2 rounded-md shadow-sm hover:shadow-lg"
          to="/"
        >Back to Home</Link>
      </div>
    </>
  )
}
