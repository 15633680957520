import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

export default function NavBar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  
  return (
    <div className="w-full bg-white rounded-b-3xl">
      <header>
        <nav className="flex justify-between items-center">
          
          <Link className="text-3xl md:text-5xl font-bold m-6" to="/">Personal Todo list</Link>

          <div>
            {user && (
              <div className="flex">
                <p className="font-bold">hi , {user.displayName}</p>
                <button
                  onClick={logout}
                  className="border px-3 mx-3 rounded-lg"
                >Logout</button>
              </div>
            )}
            {!user && (
              <div className="border mx-3 rounded-lg">
                <Link className="px-3 border-r" to="/login">Login</Link>
                <Link className="px-3" to="/signup">Signup</Link>
              </div>
            )}
          </div>
        </nav>
      </header>
    </div>
  )
}
