import { useState } from "react";
import { useSignup } from "../hooks/useSignup";

import loading from "../assets/loading.svg";

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const { error, isPending, signup } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(email, password, name);
  }
  return (
    <form
      className="mt-24 flex flex-col justify-center items-center"
      onSubmit={handleSubmit}
    >
      <h2 className="text-4xl font-bold text-center mb-3">SignUp</h2>
      <input
        className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2"
        type="text"
        placeholder="Desplay Name"
        onChange={e => setName(e.target.value)}
        value={name}
      />
      <input
        className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2"
        type="email"
        placeholder="Email"
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <input
        className="mb-3 border-2 rounded-lg focus:border-gray-400 focus:outline-none p-2"
        type="password"
        placeholder="Password"
        onChange={e => setPassword(e.target.value)}
        value={password}
      />
      <button
        className="bg-green-600 text-white px-5 border py-2 rounded-md shadow-sm hover:shadow-lg disabled:opacity-70"
        type="submit"
        disabled={isPending}
      >SignUp 
      {isPending && <img className="inline-block w-5 ml-2 animate-spin" src={loading} alt="..."/>}
      </button>

      {error && <p className="text-red-500 text-center">{error}</p>}

    </form>
  )
}
