import Cards from "../components/Cards"
import { Link } from "react-router-dom"

import add from "../assets/plus-square.svg"
import loading from "../assets/loading.svg"

import { useData } from "../hooks/useData"

export default function Home() {
  const { data, isPending, err } = useData()
  return (
    <div className="bg-white mt-4 rounded-3xl">
      <div className="row row-cols-1 row-cols-md-3 g-0">
        <div className="col">
          <div className="bg-gray-200 m-3 rounded-3xl overflow-hidden">
            <div className="bg-indigo-50 flex justify-between items-center">
              <div className="text-3xl p-3">Todo</div>
              <Link className="py-3 px-4" to="/create">
                <img className="w-7" src={add} alt="add" />
              </Link>
            </div>
            <div className="p-2">
              {isPending && <div className="flex justify-center">
                <img
                  className="w-10 animate-spin"
                  src={loading}
                  alt="loading..." />
              </div>}
              {data && <Cards data={data} list={'todo'} />}
              
              {err && <div>{err}</div>}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="bg-gray-200 m-3 rounded-3xl overflow-hidden">
            <div className="bg-indigo-50">
              <div className="text-3xl p-3">Progress</div>
            </div>
            <div className="p-2">
              {isPending && <div className="flex justify-center">
                <img
                  className="w-10 animate-spin"
                  src={loading}
                  alt="loading..." />
              </div>}
              {data && <Cards data={data} list={'progress'}/>}
              
              {err && <div>{err}</div>}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="bg-gray-200 m-3 rounded-3xl overflow-hidden">
            <div className="bg-indigo-50">
              <div className="text-3xl p-3">Completed</div>
            </div>
            <div className="p-2">
              {isPending && <div className="flex justify-center">
                <img
                  className="w-10 animate-spin"
                  src={loading}
                  alt="loading..." />
              </div>}
              {data && <Cards data={data} list={'completed'} />}
              
              {err && <div>{err}</div>}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
