import { useEffect, useState } from "react"
import { useAuthContext } from "./useAuthContext"
import { auth } from "../firebase/config"

export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setError(null)
    setIsPending(true)

    try {
      await auth.signOut()

      dispatch({ type: "LOGOUT" })
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }

  }

  useEffect(() => setIsCancelled(true) , []);

  return { logout, error, isPending }
}