import { useState, useEffect } from "react"
import { db } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"

export const useData = () => {
  const [data, setData] = useState(null)
  const [err, setErr] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { user } = useAuthContext()

  useEffect(() => {
    let ref = db.collection("todos").where('uid', '==', user.uid)

    setIsPending(true)
    const unsub = ref.onSnapshot(Snapshot => {
      let result = []
      Snapshot.docs.forEach(doc => {
        result.push({ ...doc.data(), id: doc.id })
      })
      setData(result)
      setIsPending(false)
    }, err => {
      setErr(err)
      setIsPending(false)
    })
    return () => unsub()
    // eslint-disable-next-line
  }, [])

  return { data, err, isPending }
}