import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAcq6TNgnWLKw6X5el-VapYX72LrBiqkiU",
  authDomain: "my-new-training-project-9a121.firebaseapp.com",
  projectId: "my-new-training-project-9a121",
  storageBucket: "my-new-training-project-9a121.appspot.com",
  messagingSenderId: "932139040440",
  appId: "1:932139040440:web:e0ab90e388fdc549783e4d"
};

//initialize firebase
firebase.initializeApp(firebaseConfig);

//initialize service
const db = firebase.firestore();
const auth = firebase.auth();

//timestamp
const timestamp = firebase.firestore.Timestamp

export { db, auth, timestamp };
